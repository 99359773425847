import {DataStatus, Separator, Table} from 'platform/components';
import {Box, Space, VStack} from 'platform/foundation';
import styled from 'styled-components';

import {head, length} from 'ramda';

import {DrillDownLevelName} from '@price-report/shared';

import {suffixTestId, TestIdProps} from 'shared';

import {useDrillDownQuery} from '../../../../store/omneticApi';
import {DrillDownLevelsConfigurator} from './components/DrillDownLevelsConfigurator';
import {DrillDownRow} from './components/DrillDownRow';
import {useLevelConfigurationOrderQueryState} from './hooks/useLevelConfigurationOrderQueryState';
import {getKeyFromLevels} from './utils/getKeyFromLevels';
import {getLevelConfigurations} from './utils/getLevelConfigurations';
import {getPermanentLevelFilters} from './utils/getPermanentLevelFilters';
import {getTableColumns} from './utils/getTableColumns';

export interface DrillDownProps extends TestIdProps {
  currency: string;
  regions: string[];
  permanentFilters?: Partial<Record<DrillDownLevelName, string>>;
  hiddenLevels?: DrillDownLevelName[];
  selectedRow: Record<DrillDownLevelName, string>;
}

export function DrillDown(props: DrillDownProps) {
  const defaultLevelConfigurations = getLevelConfigurations(props.hiddenLevels);
  const [levelConfigurations, setLevelConfigurations] = useLevelConfigurationOrderQueryState(
    defaultLevelConfigurations
  );

  const permanentLevelFilters = getPermanentLevelFilters(props.permanentFilters);
  const {data, isLoading, isFetching, isError} = useDrillDownQuery({
    levels: [
      ...permanentLevelFilters,
      {
        level: head(levelConfigurations)?.name ?? 'make',
        values: [],
        priority: length(permanentLevelFilters) + 1,
      },
    ],
    currency: props.currency,
    regions: props.regions,
  });

  const columns = getTableColumns(props.currency);

  return (
    <VStack>
      <DrillDownLevelsConfigurator value={levelConfigurations} onChange={setLevelConfigurations} />
      <Space vertical={3} />
      <DataStatus isLoading={isLoading || isFetching} isError={isError} minHeight={50}>
        <ScrollWithHiddenScrollbarInCypress>
          <Box minWidth={200} paddingBottom={1}>
            <Separator orientation="horizontal" spacing={0} />
            <Table variant="stripped" columns={columns} data-testid={suffixTestId('table', props)}>
              {data?.statisticsAggregated.map((item) => (
                <DrillDownRow
                  key={getKeyFromLevels(item.levels)}
                  depth={0}
                  data={item}
                  levelConfigurations={levelConfigurations}
                  currency={props.currency}
                  translations={data.translations}
                  regions={props.regions}
                  selectedRow={props.selectedRow}
                />
              ))}
            </Table>
          </Box>
        </ScrollWithHiddenScrollbarInCypress>
      </DataStatus>
    </VStack>
  );
}

// Hide scrollbar, so snapshot tests won't fail (scrollbar doesn't look the same on different platforms)
const ScrollWithHiddenScrollbarInCypress = styled.div`
  overflow: auto;

  ${() =>
    // @ts-ignore
    window.Cypress &&
    `
      scrollbar-width: none; /* Firefox */
      -ms-overflow-style: none; /* Edge */

      &::-webkit-scrollbar { /* Chrome, Safari and Opera */
        display: none;
      }
  `}
`;
